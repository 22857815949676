import { Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngneat/effects';
import { map } from 'rxjs';

import { IntegratedSearchAuthDataAccessAuthActions as AuthActions } from '@ess/integrated-search/auth/data-access';
import { IntegratedSearchProjectsDataAccessActions as ProjectsActions } from '@ess/integrated-search/projects/data-access';

import { IntegratedSearchTacticsDataAccessChartsRepository } from './integrated-search-tactics-data-access-charts.repository';

@Injectable()
export class IntegratedSearchTacticsDataAccessChartsEffects {
  setActiveTabsConfig$ = createEffect((actions) =>
    actions.pipe(
      ofType(ProjectsActions.setActiveProjectSuccess),
      map(({ projectId }) => this.__repo.setActiveProject(projectId)),
    ),
  );

  setActiveUser$ = createEffect((actions) =>
    actions.pipe(
      ofType(AuthActions.authSuccessFinished),
      map(({ user }) => this.__repo.setActiveUser(user)),
    ),
  );

  constructor(private readonly __repo: IntegratedSearchTacticsDataAccessChartsRepository) {}
}

import { NgModule } from '@angular/core';
import { provideEffects } from '@ngneat/effects-ng';

import { IntegratedSearchTacticsDataAccessChartsEffects } from './state/integrated-search-tactics-data-access-charts.effects';
import { IntegratedSearchTacticsDataAccessChartsRepository } from './state/integrated-search-tactics-data-access-charts.repository';

@NgModule({
  providers: [
    IntegratedSearchTacticsDataAccessChartsRepository,
    provideEffects(IntegratedSearchTacticsDataAccessChartsEffects),
  ],
})
export class IntegratedSearchTacticsDataAccessModule {}

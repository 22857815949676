import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { catchError, EMPTY, Observable, of, tap } from 'rxjs';

import { ISTacticFilters, ISTacticPerformanceFilters } from '@ess/integrated-search/filters/shared/utils';
import {
  IS_TACTIC_ACTION_MESSAGE,
  IS_TACTIC_PERFORMANCE_DICTIONARY,
  ISAddToNegativesValue,
  ISApprovals,
  ISRecommendation,
  ISRecommendationSummary,
  ISTacticAction,
  ISTacticsEnum,
} from '@ess/integrated-search/tactics/shared/utils';
import { SharedDataAccessApiService } from '@ess/shared/data-access';
import { SharedFeatureServicesUserInteractionService } from '@ess/shared/feature/services';
import { EssGtagHelper, EssRequestParamsHelper } from '@ess/shared/utils/helpers';
import { SharedLineChartData } from '@ess/shared/utils/models';

@Injectable()
export class IntegratedSearchTacticsDataAccessSharedTacticsApiService {
  constructor(
    private readonly __api: SharedDataAccessApiService,
    private readonly __uiService: SharedFeatureServicesUserInteractionService,
  ) {}

  getRecommendationsSummary<R extends ISRecommendation>(
    resource: ISTacticsEnum,
    projectId: number,
    { timespan: { start_date, end_date }, ...filters }: ISTacticFilters,
  ): Observable<ISRecommendationSummary<R>> {
    return this.__api
      .post<ISRecommendationSummary<R>, Params>(
        `tactics/${resource}/${projectId}/summary`,
        EssRequestParamsHelper.getData({
          start_date,
          end_date,
          ...filters,
        }),
      )
      .pipe(
        catchError(() => {
          this.__uiService.openSnackbar({
            data: {
              type: 'error',
              text: 'There was an error while fetching recommendations summary. Please try again later.',
            },
          });
          return of([]);
        }),
      );
  }

  getChartData(
    resource: ISTacticsEnum,
    projectId: number,
    { timespan: { start_date, end_date }, ...filters }: ISTacticFilters,
    performanceFilters?: ISTacticPerformanceFilters,
  ): Observable<SharedLineChartData> {
    return this.__api
      .post<SharedLineChartData, Params>(
        `tactics/${resource}/${projectId}/chart`,
        EssRequestParamsHelper.getData({
          start_date,
          end_date,
          ...filters,
          ...performanceFilters,
        }),
      )
      .pipe(
        catchError(() => {
          this.__uiService.openSnackbar({
            data: {
              type: 'error',
              text: 'There was an error while fetching chart data. Please try again later.',
            },
          });
          return of({ categories: [], series: [] });
        }),
      );
  }

  performAction(
    resource: ISTacticsEnum,
    { recommendation, keyword, country, search_term, action }: ISTacticAction,
    projectId: number,
  ): Observable<never> {
    return this.__api
      .post<never, ISTacticAction>(
        `tactics/${resource}/${projectId}/${IS_TACTIC_PERFORMANCE_DICTIONARY[resource].entityActionPath}`,
        {
          recommendation,
          keyword,
          country,
          search_term,
          action,
        },
      )
      .pipe(
        tap({
          next: () => {
            this.__uiService.openSnackbar({
              data: {
                text: IS_TACTIC_ACTION_MESSAGE.success[action!] ?? 'Recommendation approved.',
                type: 'success',
              },
            });
            EssGtagHelper.sendEvent('isd_recommendation_status_change', {
              event_action: `Recommendation status change`,
              event_category: 'Approval',
              performed_action: action,
              project_id: projectId,
            });
          },
          error: () => {
            this.__uiService.openSnackbar({
              data: {
                text:
                  IS_TACTIC_ACTION_MESSAGE.error[action!] ??
                  'There were some problems. Please try to approve this recommendation again.',
                type: 'error',
              },
            });
          },
        }),
      );
  }

  getApprovals<R extends ISRecommendation>(
    resource: ISTacticsEnum,
    projectId: number,
    {
      timespan: { start_date, end_date },
      country,
      search_term__like,
      assigned_category_value_ids,
      not_assigned_category_ids,
      ad_group_ids,
      campaign_ids,
    }: ISTacticFilters,
    performanceFilters?: ISTacticPerformanceFilters,
  ): Observable<ISApprovals<R>[]> {
    return this.__api
      .get<ISApprovals<R>[]>(`tactics/${resource}/${projectId}/approvals`, {
        params: EssRequestParamsHelper.getParams({
          start_date,
          end_date,
          country,
          search_term__like,
          assigned_category_value_ids,
          not_assigned_category_ids,
          ad_group_ids,
          campaign_ids,
          ...performanceFilters,
        }),
      })
      .pipe(
        catchError(() => {
          this.__uiService.openSnackbar({
            data: {
              type: 'error',
              text: 'There was an error while fetching approved recommendations. Please try again later.',
            },
          });
          return of([]);
        }),
      );
  }

  getAddToNegativesValue(
    projectId: number,
    { timespan: { start_date, end_date }, ...filters }: ISTacticFilters,
  ): Observable<ISAddToNegativesValue | null> {
    return this.__api
      .post<ISAddToNegativesValue, Params>(
        `tactics/channel-overlap/${projectId}/add-to-negatives-value`,
        EssRequestParamsHelper.getData({
          start_date,
          end_date,
          ...filters,
        }),
      )
      .pipe(
        catchError(() => {
          this.__uiService.openSnackbar({
            data: {
              type: 'error',
              text: 'There was an error while fetching value. Please try again later.',
            },
          });
          return of(null);
        }),
      );
  }

  downloadCSV(
    resource: ISTacticsEnum,
    projectId: number,
    { timespan: { start_date, end_date }, ...filters }: ISTacticFilters,
  ): Observable<string> {
    return this.__api
      .post<string, Params>(
        `tactics/${resource}/${projectId}/csv`,
        EssRequestParamsHelper.getData({
          start_date,
          end_date,
          ...filters,
        }),
        {
          responseType: 'text',
        },
      )
      .pipe(
        catchError(() => {
          this.__uiService.openSnackbar({
            data: {
              type: 'error',
              text: 'There was an error while downloading the CSV file.',
            },
          });
          return EMPTY;
        }),
      );
  }
}

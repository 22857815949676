import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { IntegratedSearchFiltersDataAccessTacticsFacade } from '@ess/integrated-search/filters/data-access';
import { ISTacticFilters } from '@ess/integrated-search/filters/shared/utils';
import { IntegratedSearchProjectsDataAccessFacade } from '@ess/integrated-search/projects/data-access';
import {
  IS_TACTICS_RESOURCE_PATH,
  ISTacticMetric,
  ISTacticMetricsList,
  ISTacticsEnum,
} from '@ess/integrated-search/tactics/shared/utils';
import { AbstractSharedDataAccessListService, SharedDataAccessApiService } from '@ess/shared/data-access';

@Injectable()
export class IntegratedSearchTacticsDataAccessSharedTacticMetricsListService<
  U extends ISTacticMetric,
> extends AbstractSharedDataAccessListService<U, ISTacticMetricsList<U>> {
  set projectId(value: number | null) {
    this.__projectId = value;
  }

  activeProject$ = this.__projectsFacade.activeProject$;

  private __projectId: number | null = null;

  protected get resourcePath(): string {
    return `tactics/${this.__resource}/${this.__projectId}`;
  }

  constructor(
    api: SharedDataAccessApiService,
    @Inject(IS_TACTICS_RESOURCE_PATH) private readonly __resource: ISTacticsEnum,
    private readonly __filtersFacade: IntegratedSearchFiltersDataAccessTacticsFacade,
    private readonly __projectsFacade: IntegratedSearchProjectsDataAccessFacade,
  ) {
    super(api);
    this._method = 'post';
  }

  filters$(): Observable<ISTacticFilters> {
    return this.__filtersFacade.filters$.pipe(
      tap(() => (this.projectId = this.__projectsFacade.activeProjectEntity!.project_id)),
    );
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ISTacticMetric, ISTacticMetricsList } from '@ess/integrated-search/tactics/shared/utils';
import { AbstractSharedDataAccessListService, SharedDataAccessApiService } from '@ess/shared/data-access';
import { SharedTimespan } from '@ess/shared/utils/models';

@Injectable()
export class IntegratedSearchTacticsDataAccessSharedPerformanceKeywordListService<
  U extends ISTacticMetric,
> extends AbstractSharedDataAccessListService<U, ISTacticMetricsList<U>> {
  set projectId(value: number | null) {
    this.__projectId = value;
  }

  set timespan(value: SharedTimespan) {
    this.__timespan$.next(value);
  }

  set searchTerm(value: string) {
    this.__searchTerm = value;
  }

  readonly timespanChange$: Observable<SharedTimespan | null>;

  private __projectId: number | null = null;
  private __searchTerm = '';
  private readonly __timespan$: BehaviorSubject<SharedTimespan | null> = new BehaviorSubject<SharedTimespan | null>(
    null,
  );

  protected get resourcePath(): string {
    return `search-term-metrics/${this.__projectId}/${encodeURIComponent(this.__searchTerm)}/keywords`;
  }

  constructor(api: SharedDataAccessApiService) {
    super(api);
    this.timespanChange$ = this.__timespan$.asObservable();
  }
}
